import styled from 'styled-components';
import { ThumbnailWrapper, ThumbnailImage } from '../Thumbnail/Thumbnail.styles';

export const ThumbnailsWrapper = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    justify-content: space-between;
  }

  img {
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  }
`;

export const LargeThumbnail = styled.a`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  ${ThumbnailWrapper} {
    width: 100%;

    ${ThumbnailImage} {
      width: 100%;
    }
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0;

    ${ThumbnailWrapper} {
      width: 338px;
    }
  }
`;

export const SmallThumbnail = styled.a`
  display: block;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  }
`;
