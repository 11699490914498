import Link from 'next/link';

import { Carousel, Thumbnail, SectionTitle, SectionWrapper } from '@/components';

import { SmallThumbnail, ThumbnailsWrapper, LargeThumbnail } from './HighlightSection.styles';

import { ResponsiveCollection, type ThumbnailProps, ThumbnailVariant } from '@/interfaces';

import { replacePath } from '@/utils';

import { ACCEPTED_ARTICLE_TYPES, RouteName } from '@/constants';
import { useResponsive } from '@/hooks';

interface IHighlightSectionProps {
  title?: string;
  thumbnails?: ThumbnailProps[];
}

const HighlightSection: React.FC<IHighlightSectionProps> = ({ title, thumbnails = [] }) => {
  const [firstThumbnail, ...restThumbnails] = thumbnails;
  const hasThumbnail = thumbnails && thumbnails.length > 0;
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  if (!hasThumbnail) return null;

  return (
    <SectionWrapper>
      {title && <SectionTitle>{title}</SectionTitle>}
      {hasThumbnail && (
        <ThumbnailsWrapper>
          <Link
            href={replacePath(RouteName.Article, [
              firstThumbnail.articleType && ACCEPTED_ARTICLE_TYPES[firstThumbnail.articleType],
              firstThumbnail.slug,
            ])}
            passHref
            legacyBehavior
          >
            <LargeThumbnail>
              <Thumbnail {...firstThumbnail} priority />
            </LargeThumbnail>
          </Link>
          {isMobile ? (
            restThumbnails.length > 0 && <Carousel carouselData={restThumbnails} slidesToShow={2} infinite />
          ) : (
            <div>
              {restThumbnails.map((thumbnail, index) => (
                <Link
                  key={index}
                  href={replacePath(RouteName.Article, [
                    thumbnail.articleType && ACCEPTED_ARTICLE_TYPES[thumbnail.articleType],
                    thumbnail.slug,
                  ])}
                  passHref
                  legacyBehavior
                >
                  <SmallThumbnail>
                    <Thumbnail {...thumbnail} variant={ThumbnailVariant.SMALL_DEFAULT} />
                  </SmallThumbnail>
                </Link>
              ))}
            </div>
          )}
        </ThumbnailsWrapper>
      )}
    </SectionWrapper>
  );
};

export default HighlightSection;
